<template>
  <div class="container" @dragover.prevent @drop.prevent="handleDrop">
    <h1>图片压缩</h1>
    <div class="note">
      <h3>注意事项</h3>
      <div class="content">
        <span>一：压缩质量范围0-100，影响输出压缩图片的清晰度和图片大小</span>
        <span>二：转换格式决定最终输出的压缩图片的格式</span>
      </div>
    </div>
    <div class="upload-area" @click="openFileInput">
      <input type="file" multiple @change="handleFileInput" ref="fileInput" />
      <p>点击选择文件或拖拽图片到任意位置 (最多10张，每张不超过5MB)</p>
    </div>
    <button @click="openFileInput">选择文件</button>
    <!-- <div class="upload-area">
      <input
        type="file"
        class="upload-btn"
        multiple
        @change="handleFileInput"
        ref="fileInput"
      />
      <p>点击选择文件或拖拽图片到任意位置 (最多10张，每张不超过5MB)</p>
      <button class="upload-btn" @click="openFileInput">选择文件</button>
    </div> -->
    <div class="settings">
      <label for="quality">压缩质量:</label>
      <input type="range" id="quality" v-model="quality" min="1" max="100" />
      <span>{{ quality }}</span>
      <label for="format">转换格式:</label>
      <select id="format" v-model="format">
        <option value="jpeg">JPEG</option>
        <option value="png">PNG</option>
        <option value="webp">WEBP</option>
      </select>
    </div>
    <div class="image-preview" v-if="compressedImages.length">
      <h2>压缩后的图片</h2>
      <div class="image-list">
        <div
          v-for="(image, index) in compressedImages"
          :key="index"
          class="image-item"
        >
          <img :src="image.url" class="compressed-image" />
          <div class="image-details">
            <div class="name-and-size">
              <p>{{ image.name }}</p>
              <p>{{ image.originalSize }} {{ image.format }}</p>
            </div>
            <div class="compressed-size">
              <p>{{ image.compressedSize }}</p>
              <p>{{ image.compressionRatio }}</p>
            </div>
          </div>
          <div class="progress-bar">
            <div
              class="progress"
              :style="{ width: image.progress + '%' }"
            ></div>
          </div>
        </div>
      </div>
      <button
        v-if="isDownloadVisible"
        @click="downloadZip"
        class="download-btn"
      >
        下载压缩包
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      files: [],
      compressedImages: [],
      isDownloadVisible: false,
      quality: 80, // 默认压缩质量
      format: "jpeg", // 默认格式
    };
  },
  mounted() {
    window.addEventListener("dragover", this.preventDefaults, false);
    window.addEventListener("drop", this.handleDrop, false);
  },
  beforeDestroy() {
    window.removeEventListener("dragover", this.preventDefaults, false);
    window.removeEventListener("drop", this.handleDrop, false);
  },
  methods: {
    preventDefaults(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    handleDrop(event) {
      this.preventDefaults(event);
      const droppedFiles = event.dataTransfer.files;
      this.handleFiles(droppedFiles);
    },
    handleFileInput(event) {
      const selectedFiles = event.target.files;
      this.handleFiles(selectedFiles);
    },
    async handleFiles(files) {
      const newFiles = [];
      for (let i = 0; i < files.length; i++) {
        if (this.files.length + newFiles.length >= 10) {
          alert("超出最大限度10张，请重新上传");
          return;
        }
        if (files[i].size <= 5 * 1024 * 1024) {
          newFiles.push(files[i]);
        } else {
          alert(`文件 ${files[i].name} 超过了5MB限制，将被忽略。`);
        }
      }
      this.files.push(...newFiles);
      await this.compressFiles();
    },
    async compressFiles() {
      this.compressedImages = [];
      const formData = new FormData();
      formData.append("quality", this.quality);
      formData.append("format", this.format);
      for (let file of this.files) {
        formData.append("files", file);
      }
      try {
        const response = await axios.post(
          "http://www.soutudashi.com/compress",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.updateProgress(progress);
            },
          }
        );
        this.compressedImages = response.data.map((image) => ({
          ...image,
          progress: 100,
          url: `data:image/${this.format};base64,${image.data}`,
        }));
        this.isDownloadVisible = true;
      } catch (error) {
        console.error("Error while compressing image:", error);
      }
    },
    async downloadZip() {
      const zip = new JSZip();
      for (let i = 0; i < this.compressedImages.length; i++) {
        const imgUrl = this.compressedImages[i].url;
        const response = await fetch(imgUrl);
        const blob = await response.blob();
        zip.file(this.compressedImages[i].name, blob);
      }
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "compressed_images.zip");
    },
    updateProgress(progress) {
      this.compressedImages.forEach((image) => {
        image.progress = progress;
      });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
  },
};
</script>

<style>
.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* .upload-area {
  border: 2px dashed #ccc;
  padding: 40px;
  cursor: pointer;
}

.upload-area p {
  margin: 0;
} */

.upload-area {
  border: 2px dashed #ccc;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.upload-area input {
  display: none;
}
.upload-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-btn:hover {
  background-color: #45a049;
}

.settings {
  margin-top: 20px;
  text-align: left;
}

.settings label {
  display: block;
  margin-bottom: 5px;
}

.settings input[type="range"],
.settings select {
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
}

.image-preview {
  margin-top: 20px;
}

.image-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.image-item img {
  max-width: 100px;
  margin-right: 10px;
}

.image-details {
  flex-grow: 1;
  text-align: left;
}

.name-and-size {
  margin-bottom: 10px;
}

.compressed-size {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress {
  height: 10px;
  background-color: #4caf50;
}

.download-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-btn:hover {
  background-color: #45a049;
}

.note {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
}

.note > .content {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 10px;
  margin-bottom: 10px;
}
.content > span {
  display: block;
}
</style>
